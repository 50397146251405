<i18n src="@/locales/pages/portfolio.json"></i18n>

<template>
  <div class="font" :style="{ fontFamily: this.customCssName || family }">
    <p class="heading">{{ family }}</p>
    <p class="demo">
      {{ $t('portfolio.general.typography.demonstrationChars') }}
    </p>
  </div>
</template>

<script>
import WebFont from 'webfontloader';

export default {
  name: 'PortfolioFont',
  props: {
    family: {
      type: String,
      required: true,
    },
    customUrl: String,
    customCssName: String,
  },
  mounted() {
    if (!this.customUrl)
      WebFont.load({
        google: {
          families: [this.family],
        },
      });
    else {
      let head = document.head || document.getElementsByTagName('head')[0],
        link = document.createElement('link');

      head.appendChild(link);

      link.rel = 'stylesheet';
      link.href = this.customUrl;
    }
  },
};
</script>

<style lang="scss" scoped>
.font {
  .heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: $foreground;

    @media all and ($mobile) {
      font-size: 1.125rem;
    }
  }

  .demo {
    margin-top: 8px;
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.875rem;
    text-align: left;
    color: $foreground;

    @media all and ($mobile) {
      font-size: 1rem;
      color: $text-color;
    }
  }
}
</style>
