<i18n src="@/locales/pages/portfolio.json"></i18n>

<template>
  <section class="intro">
    <div class="content">
      <div
        class="dot-pattern rellax"
        data-rellax-speed="-1"
        data-rellax-percentage=".5"
      ></div>
      <div class="title">
        <h1>
          <slot name="title"></slot>
        </h1>
        <p class="subtitle">
          <slot name="subtitle"></slot>
        </p>
      </div>
      <div class="inner">
        <p class="description">
          <slot name="description"></slot>
        </p>
        <div class="details">
          <p class="headline">
            {{ $t('portfolio.general.intro.headline') }}
          </p>
          <table>
            <tr>
              <th>
                {{ $t('portfolio.general.intro.services') }}
              </th>
              <td>
                <slot name="services"></slot>
              </td>
            </tr>
            <tr>
              <th>
                {{ $t('portfolio.general.intro.tools') }}
              </th>
              <td>
                <slot name="tools"></slot>
              </td>
            </tr>
            <tr>
              <th>
                {{ $t('portfolio.general.intro.year') }}
              </th>
              <td>
                <slot name="year"></slot>
              </td>
            </tr>
            <tr v-if="!!$slots.code">
              <th>
                {{ $t('portfolio.general.intro.code') }}
              </th>
              <td>
                <slot name="code"></slot>
              </td>
            </tr>
            <tr>
              <th>
                {{ $t('portfolio.general.intro.preview') }}
              </th>
              <td>
                <slot name="preview"></slot>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PortfolioIntro',
};
</script>

<style lang="scss" scoped>
.intro {
  width: 100%;
  padding: 128px 0;
  overflow: hidden;

  @media all and ($mobile) {
    padding: 96px 0;
  }

  .content {
    max-width: $max-view-width;
    height: auto;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;

    .dot-pattern {
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      &::before {
        content: '';
        display: block;
        width: 260px;
        height: 120px;
        transform: translate(60%, -40%);
        z-index: 0;

        @include circle-pattern(0.2);

        @media all and ($mobile) {
          transform: translate(80%, -75%);
        }
      }
    }

    .title {
      margin-bottom: 96px;
      line-height: normal;

      @media all and ($mobile) {
        margin-bottom: 64px;
      }

      h1 {
        font-size: 3.375rem;
        font-weight: 700;
        text-align: left;
        color: $foreground;
        margin: 0 0 8px 0;

        @media all and ($mobile) {
          font-size: 2rem;
          margin: 0 0 4px 0;
        }
      }

      .subtitle {
        font-size: 1.5rem;
        font-weight: 400;
        text-align: left;
        color: $text-color;

        @media all and ($mobile) {
          font-size: 1.125rem;
        }
      }
    }

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media all and ($mobile) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }

      .description {
        width: 50%;
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
        color: $text-color;

        @media all and ($mobile) {
          width: 100%;
          margin: 0 0 48px 0;
        }
      }

      .details {
        width: 43%;

        @media all and ($mobile) {
          width: 100%;
        }

        .headline {
          font-weight: 700;
          text-align: left;
          color: $foreground;
          margin-bottom: 8px;
        }

        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 12px;

          tr {
            th {
              font-size: 1rem;
              line-height: normal;
              font-weight: 600;
              color: $text-color;
              text-align: left;
              vertical-align: top;
              padding-right: 24px;
            }

            td {
              line-height: normal;

              a {
                outline: none;
                color: $text-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
